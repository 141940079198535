<template>
  <div class="app-header flex flex-justify--center flex-align--center">
    <div class="app-header__logo">
      <ebba-logo></ebba-logo>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    EbbaLogo: () => import("@/components/ebba/ebba-logo"),
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">
:root {
	--app-header-height: calc(2.75rem + (2vw));
}

.app-header {
// background: #ff00003b;
  height: var(--app-header-height);
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 50;
  &__logo {
    width: max(35vw * 0.8, 80px);
    padding: 0 15px;
    svg {
      margin-left: auto;
      margin-right: auto;
      width: 6rem;
    }
  }
}
</style>
